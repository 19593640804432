<template>
  <div class="col-xl-4 px-0">
    <div
      class="card card-custom gutter-b h-100"
      :class="error.active ? 'bordered-card-danger ml-3' : 'bordered-card-primary ml-3'"
    >
      <div class="card-header px-5">
        <div class="card-title d-flex justify-content-between w-100">
          <h3 class="card-label">
            {{ $t('GENERAL_WIDGETS.TEXT_ANALYSIS.TITLE') }}
          </h3>
          <div class="card-toolbar">
            <amChartsExport
              v-if="chart != null && currentUser.relation_to_project != 'observer'"
              position="right"
              :dataTypes="['png','jpg','svg','json','csv','pdfdata']"
              :chart="chart"
              title="text_analysis"
            >
            </amChartsExport>
          </div>
        </div>
      </div>
      <div class="card-body px-5 py-5">
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>          
        </div>
        <b-alert v-if="error.active" show variant="light">
          {{ error.message }}
        </b-alert>
        <div :class="{ 'd-none' : projectStage < 3 || textAnalysis.length == 0 }" id="chartdiv">
        </div>

        <!--start::Placeholder for project not started-->
        <b-alert v-if="projectStage < 3 && !error.active && loadingComplete" show variant="light">
          {{ $t('GENERAL_WIDGETS.TEXT_ANALYSIS.NOT_READY') }}
        </b-alert>
        <!--end::Placeholder for project not started-->

        <!--start::Placeholder for project has no responses-->
        <b-alert v-if="projectStage >= 3 && !error.active && textAnalysis.length == 0 && loadingComplete" show variant="light">
          {{ $t('GENERAL_WIDGETS.TEXT_ANALYSIS.NOT_READY_2') }}
        </b-alert>
        <!--end::Placeholder for project has no responses-->
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud"; 
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import amChartsExport from "@/view/components/AMChartExport";

export default {
  name: "ProjectTextAnalysis",

  props: {
    textAnalysis: { type: Array, required: true },
    pageLoader: { type: Object, required: true },
    error: { type: Object, required: true },
    projectStage: { type: Number, required: true }
  },

  data() {
    return {
      chart: null
    }
  },

  components: {
    amChartsExport
  },

  methods: {
    generateChart() {
      if (this.projectStage >= 3) {
        am4core.useTheme(am4themes_animated);
        var chart = am4core.create("chartdiv", am4plugins_wordCloud.WordCloud);
        var series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

        series.accuracy = 3;
        series.step = 15;
        series.angles = [0];
        series.maxCount = 50;
        series.labels.template.tooltipText = "{word}: {value}";
        series.fontFamily = "sans-serif";
        series.maxFontSize = am4core.percent(10);
        series.minFontSize = am4core.percent(4);
        series.heatRules.push({
            "target": series.labels.template,
            "property": "fill",
            "min": am4core.color("#748592"),
            "max": am4core.color("#18344a"),
            "dataField": "value"
        });

        series.data = this.textAnalysis;
        series.dataFields.word = "word";
        series.dataFields.value = "count";

        chart.exporting.adapter.add("data", function(data) {
          data.data = series.data
          return data;
        });

        this.chart = chart;
      }
    }
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  watch: {
    loadingComplete: function(newValue, oldValue) {
      if(oldValue == false && newValue == true)
      {
        this.generateChart();
      }
    },
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
  #chartdiv {
    width: 100%;
    height: 435px;
  }
</style>