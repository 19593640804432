<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column col-xxl-5 px-0">
          <div class="form-group mb-0 col-xxl-10 px-0">
            <input
              id="activity_table_search"
              v-model="search"
              type="text"
              :placeholder="$t('GENERAL.INPUT.SEARCH')"
              class="form-control form-control-solid h-auto py-3 px-5"
              :disabled="loadingComplete == false"
            />
          </div>
        </h3>
        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li>
              <span class="label label-lg label-primary label-inline mr-2">
                {{ filters.segment.name }}
                <a href="javascript:void(0)" @click="emitSegmentReset()">
                  <i v-if="filters.segment.id != null" class="fas fa-times pl-3 text-light font-size-sm"></i>
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body py-0">
        <div
          class="table-responsive"
          :style="'height:auto; height:' + this.calculatedTableHeight +'px'">
          <table
            id="activity_table"
            class="table table-head-custom table-vertical center table-hover"
            style="table-layout:auto; overflow: auto"
          >
            <thead class="sticky">
				      <tr>
					      <th class="px-2 py-3" rowspan="2" style="width:30px"></th>
					      <th class="px-2 py-3" rowspan="2" style="min-width:400px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.ACTIVITY') }}
                </th>
					      <th class="px-2 py-3 text-center" rowspan="2" style="width:70px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.STATUS') }}
                </th>
					      <th colspan="2" class="border-bottom-0 py-2 px-4 posts text-center">
                  {{ $t('TABLES.ACTIVITY_HEADERS.POSTS') }}
                </th>
								<th colspan="4" class="border-bottom-0 py-2 px-4 notes text-center">
                  {{ $t('TABLES.ACTIVITY_HEADERS.ENGAGEMENT') }}
                </th>
                <th class="px-5 py-3 text-center" rowspan="2" style="min-width:170px; width:260px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.PARTICIPANT_RESPONSE_RATE') }}
                </th>
                <th class="pr-4 py-3 text-right" style="width:110px" rowspan="2">
                  {{ $t('TABLES.ACTIVITY_HEADERS.ACTIONS') }}
                </th>
				      </tr>
              <tr>
					      <th class="px-2 pb-3 pt-2 sub posts text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.TOTAL') }}
                </th>
					      <th class="px-2 pb-3 pt-2 sub posts text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.NEW') }}
                </th>
								<th class="px-2 pb-3 pt-2 sub notes text-center" style="width:100px; max-width:110px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.FAVOURITED') }}
                </th>					
								<th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.NOTES') }}
                </th>
						    <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.REPLIES') }}
                </th>
						    <th class="px-2 pb-3 pt-2 sub notes text-center" style="width:80px; max-width:90px">
                  {{ $t('TABLES.ACTIVITY_HEADERS.TAGGED') }}
                </th>		
				      </tr>
			      </thead>
            <tbody>
              <template v-for="(item, i) in activityList">
                <tr
                  v-bind:key="i"
                  v-if="
                    activityInSearch(item) &&
                      activityList.length > 0 &&
                      loadingComplete &&
                      activityInSegment(item)
                  "
                  v-on:mouseover="hovering = i"
                  v-on:mouseleave="hovering = null"
                >
                  <td class="pt-5">
                    <i
                      :class="icon(item)"
                      v-b-tooltip.hover.right
                      :title="$t(iconLabel(item))"
                    >
                    </i>
                  </td>
                  <td class="px-2 py-3 d-table-cell">
                    <ColumnActivityTitle
                      :activity="item"
                      :value="activityName(item)"
                      goTo="stream"
                    >
                    </ColumnActivityTitle>
                    <span
                      class="text-muted font-weight-bold text-muted d-block font-size-sm"
                    >
                      {{ activitySubtitle(item) }}
                      <span>{{ activityStatus(item).startPrefix }}</span>
                      <GenericDatetimeDisplay
                        :long="true"
                        :date="item.starts_on"
                        :lastUpdated="false"
                        :relative="false"
                      >
                      </GenericDatetimeDisplay>
                      <span>{{ activityStatus(item).endPrefix }}</span>
                      <GenericDatetimeDisplay
                        :long="true"
                        :date="item.ends_on"
                        :lastUpdated="false"
                        :relative="false"
                      >
                      </GenericDatetimeDisplay>
                    </span
                    >
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <a href="javascript:void(0)" @click="openModal(item.id)">
                      <i
                        class="far fa-clock"
                        :class="activityStatus(item).stageClass"
                        v-b-tooltip.hover.right
                        :title="activityStatus(item).tooltip"
                      >
                      </i>
                    </a>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnResponseCount
                      :activity="item"
                      :value="activityStats[item.id].initial_response_count"
                    >
                    </ColumnResponseCount>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnNewCount
                      :activity="item"
                      :value="activityStats[item.id].total_new"
                    >
                    </ColumnNewCount>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnFavouriteCount
                      :activity="item"
                      :value="activityStats[item.id].total_favourites"
                    >
                    </ColumnFavouriteCount>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnNoteCount
                      :activity="item"
                      :value="activityStats[item.id].total_notes"
                    >
                    </ColumnNoteCount>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnReplyCount
                      :activity="item"
                      :value="activityStats[item.id].reply_response_count"
                    >
                    </ColumnReplyCount>
                  </td>
                  <td class="text-center py-5 font-size-sm">
                    <ColumnTagCount
                      :activity="item"
                      :value="activityStats[item.id].total_tags"
                    >
                    </ColumnTagCount>
                  </td>
                  <td class="px-5 py-6">
                    <ParticipantResponseRate
                      :responseRate="activityStats[item.id]"
                      :activity="item"
                    >
                    </ParticipantResponseRate>
                  </td>
                  <td class="pr-2 pt-4 text-right d-table-cell">
                    <a
                      href="javascript:void(0)"
                      @click="applyStreamFilter({ activities: [{ id: item.id, display: item.title }], preLoading: 1 })"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                      v-b-tooltip.hover.bottom
                      :title="$t('TABLES.ACTIVITY_TOOLTIPS.REVIEW')"
                      v-if="hovering == i"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="media/svg/icons/Shopping/Chart-pie.svg" />
                      </span>
                    </a>
                    <a
                      :href="item.project.inputLink + '/#/activities/view'"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                      v-b-tooltip.hover.bottom
                      :title="$t('TABLES.ACTIVITY_TOOLTIPS.PARTICIPANT_VIEW')"
                      v-if="hovering == i"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg
                          src="media/svg/icons/Devices/Tablet.svg"
                        />
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
              <tr v-if="activityList.length == 0 && loadingComplete">
                <td class="px-2 text-center" colspan="11">
                  <span class="text-dark-65 font-weight-bold d-block py-3">
                    {{ $t('TABLES.ACTIVITY_MESSAGES.NO_ACTIVITIES_TEXT') }}
                    <router-link
                      tag="a"
                      class="text-primary"
                      to="/project/activities"
                    >
                      {{ $t('TABLES.ACTIVITY_MESSAGES.NO_ACTIVITIES_LINK') }}
                    </router-link>
                  </span>
                </td>
              </tr>
              <tr v-if="!loadingComplete && !error.active">
                <td class="px-2 text-center border-0" colspan="11">
                  <b-spinner
                    variant="primary"
                    label="Spinning"
                    class="table-loader my-3"
                  >
                  </b-spinner>
                </td>
              </tr>
              <tr v-if="error.active">
                <td class="px-2 text-center border-0" colspan="11">
                  <b-alert show variant="light">
                    {{ error.message }}
                  </b-alert>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ActivityDetails
      :activityId="modals.activityId"
      ref="activityDetailsModal"
    >
    </ActivityDetails>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

import ParticipantResponseRate from "@/view/components/ParticipantResponseRate";
import ColumnActivityTitle from "@/view/components/table-columns/general/ActivityTitle";
import ColumnResponseCount from "@/view/components/table-columns/activity_posts/InitialCount";
import ColumnNewCount from "@/view/components/table-columns/activity_posts/NewCount";
import ColumnFavouriteCount from "@/view/components/table-columns/activity_engagement/FavouriteCount";
import ColumnNoteCount from "@/view/components/table-columns/activity_engagement/NoteCount";
import ColumnReplyCount from "@/view/components/table-columns/activity_engagement/ReplyCount";
import ColumnTagCount from "@/view/components/table-columns/activity_engagement/TagCount";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";
import ActivityDetails from "@/view/content/modals/ActivityDetails";

import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";

export default {
  name: "ActivityTable",

  props: ["activityList", "activityStats", "pageLoader", "error", "filters"],

  data() {
    return {
      hovering: null,
      search: "",
      modals: {
        activityId: null
      }
    };
  },

  components: {
    ParticipantResponseRate,
    ColumnActivityTitle,
    ColumnResponseCount,
    ColumnNewCount,
    ColumnFavouriteCount,
    ColumnNoteCount,
    ColumnReplyCount,
    ColumnTagCount,
    GenericDatetimeDisplay,
    ActivityDetails
  },

  methods: {
    activityInSearch(activity) {
      let title = activity.title.toLowerCase();
      let type = activity.activity_type.name.toLowerCase();
      let stage = activity.activity_stage.name.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        type.includes(this.search.toLowerCase()) ||
        stage.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    activityInSegment(activity) {
      if(this.filters.segment.id == null) {
        return true;
      }
      else if (this.filters.segment.id == "All") {
        if (activity.project_segments.length > 0) {
          return false;
        }
        else {
          return true;
        }
      }
      else {
        if (activity.project_segments.filter(item => item.id == this.filters.segment.id).length == 0){
          return false;
        }
        else {
          return true;
        }
      }
    },
    emitSegmentReset() {
      this.$emit('resetSegment');
    },
    icon(activity) {
      return SystemIconHelper.activityType(activity.activity_type, activity.recording_override);
    },
    iconLabel(activity) {
      return SystemLabelHelper.activityType(activity.activity_type, activity.recording_override);
    },
    activityStatus(activity) {
      let tooltip = "";
      let stageClass = "";
      let start = "";
      let end = "";
      switch(activity.activity_stage.name) {
        case 'Draft':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT")}`;
          stageClass = "text-warning";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Published':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.PUBLISHED")}`;
          stageClass = "text-info";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Active':
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.ACTIVE")}`;
          stageClass = "text-success";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case 'Closed':
          `${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED")}`;
          stageClass = "text-danger";
          start = " | " + `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          end = " | " + `${this.$t("GENERAL.PREFIX.ENDED_ON")}: `;
          break;
      }
      return {
        tooltip: tooltip,
        stageClass: stageClass,
        startPrefix: start,
        endPrefix: end
      };
    },
    activityName(activity) {
      switch(activity.activity_stage.name) {
        case 'Draft':
          return `[${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT")}] ` + activity.title;
        case 'Published':
        case 'Active':
          return activity.title;
        case 'Closed':
          return `[${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED")}] ` + activity.title; 
      }
    },
    activitySubtitle(activity) {
      let type = activity.activity_type.name.toUpperCase().replace(/ /g,"_");
      type = `${this.$t("SYSTEM.ACTIVITY_TYPE." + type)}`;

      return type;
    },
    applyStreamFilter: function(filter) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    },
    openModal: function(activityId) {
      this.modals.activityId = activityId;
      this.$refs.activityDetailsModal.$refs.modal.show();
    }
  },

  computed: {
    ...mapGetters(["clientInfo"]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },

    calculatedTableHeight: function() {
      if (this.loadingComplete) {
        let headerHeight = 59.75;
        let bodyHeight = 0;

        if (this.activityList.length > 6) {
          bodyHeight = 6 * 57.5;
        } else if (this.activityList.length == 0) {
          bodyHeight = 57.5;
        } else {
          bodyHeight = this.activityList.length * 57.5;
        }

        return headerHeight + bodyHeight;
      } else {
        return 59.75 + 100;
      }
    }
  }
};
</script>
