<template>
  <a
    href="javascript:void(0)"
    class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
    @click="applyStreamFilter({ activities: [{id: activity.id, display: activity.title }], preLoading: 1 })"
  >
    {{ value | stripHTML }}
    <span
      v-if="activity.featured === 1"
      class="bg-white label label-pill label-inline ml-2 label-outline-primary"
    >
      {{ $t('FORMS.INPUTS.ACTIVITY_FEATURED_SHORT') }}
    </span>    
  </a>
</template>

<script>
import { mapGetters } from  "vuex";
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "ActivityTitle",

  props: {
    activity: { type: Object, required: true },
    value: { type: String, required: true },
  },

  methods: {
    applyStreamFilter: function(filter) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    }    
  },

  computed: {
    ...mapGetters([
      'streamFilter'
    ]),
  }
};
</script>
