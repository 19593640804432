<template>
  <div>
    <a
      href="javascript:void(0)"
      class="kt-link text-hover-primary"
      v-if="activity.system_activity_stage_id > 1"
    >
      {{ value }}
    </a>
    <p v-else class="mb-0 text-center">-</p>
  </div>
</template>

<script>
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "TagCount",

  props: {
    activity: { type: Object, required: true },
    value: { type: Number, required: true }
  },

  methods: {
    applyStreamFilter: function(filter) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    }    
  }
};
</script>
