<template>
  <div class="col-xl-4 px-0">
    <div
      class="card card-custom gutter-b h-100"
      :class="error.active ? 'bordered-card-danger mx-3' : 'bordered-card-primary mx-3'"
    >
      <div class="card-header">
        <div class="card-title">
        <h3 class="card-label">
          {{ $t('GENERAL_WIDGETS.PROJECT_SEGMENTS.TITLE') }}
        </h3>
        </div>
      </div>
      <div class="card-body px-5 py-5">
        <div v-if="loadingComplete" class="table-responsive" style="max-height:400px">
          <table
            id="project_table"
            class="table table-head-custom table-vertical center table-hover"
            style="table-layout:auto; overflow:auto"
          >
            <thead class="sticky">
				      <tr>
                <th class="px-2 py-3" rowspan="2" style="min-width:200px">
                  {{ $t('TABLES.SEGMENT_HEADERS.SEGMENT') }}
                </th>
                <th class="px-2 py-3" rowspan="2" style="min-width:80px">
                  {{ $t('TABLES.SEGMENT_HEADERS.ACTIVITIES') }}
                </th>
					      <th colspan="2" class="border-bottom-0 py-2 px-4 participants text-center">
                  {{ $t('TABLES.SEGMENT_HEADERS.PARTICIPANTS') }}
                </th>
				      </tr>
              <tr class="text-left">
                <th class="px-2 pb-3 pt-2 text-center participants sub" style="min-width:70px">
                  {{ $t('TABLES.SEGMENT_HEADERS.ACTIVE') }}
                </th>
                <th class="px-2 pb-3 pt-2 text-center participants sub" style="min-width:70px">
                  {{ $t('TABLES.SEGMENT_HEADERS.INVITED') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="px-2 py-3 d-table-cell">
                  <a
                    href="javascript:void(0)"
                    class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                    @click="emitSegment('All')"
                  >
                    {{ $t('GENERAL_WIDGETS.PROJECT_SEGMENTS.ALL_PARTICIPANTS') }}
                  </a>
                </td>
                <td class="text-center py-3 font-size-sm">
                  <a
                    href="javascript:void(0)"
                    class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                    @click="emitSegment('All')"
                  >
                    {{ segmentStats.activity_count }}
                  </a>
                </td>
                <td class="text-center py-3 font-size-sm">
                  <router-link
                    tag="a"
                    :to="{ name: 'project_users', params: { segment: 'no_segment', status: 'subscribed' } }"
                    class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                  >
                    {{ segmentStats.subscribed_count }}
                  </router-link>
                </td>
                <td class="text-center py-3 font-size-sm">
                  <router-link
                    :to="{ name: 'project_users', params: { segment: 'no_segment', status: 'invited' } }"
                    tag="a"
                    class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                  >
                    {{ segmentStats.invited_count }}
                  </router-link>
                </td>
              </tr>
              <template v-for="(itemA, i) in segmentationList">
                <tr :key="i">
                  <td class="px-2 py-3 d-table-cell">
                    <a
                      href="javascript:void(0)"
                      class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                      style="cursor: auto !important"
                    >
                      {{ itemA.name }}
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <template v-for="(itemB) in segmentList">
                  <tr :key="i + 'A' + itemB.id" v-if="itemB.project_segmentation_id == itemA.id">
                    <td class="px-2 py-3 pl-7 d-table-cell">
                      <a
                        href="javascript:void(0)"
                        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
                        @click="emitSegment(itemB)"
                      >
                        {{ itemB.name }}
                      </a>
                    </td>
                    <td class="text-center py-3 font-size-sm">
                      <a
                        href="javascript:void(0)"
                        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                        @click="emitSegment(itemB)"
                      >
                        {{ itemB.activities_count }}
                      </a>
                    </td>
                    <td class="text-center py-3 font-size-sm">
                      <router-link
                        tag="a"
                        :to="{ name: 'project_users', params: { segment: itemB.id, status: 'subscribed' } }"
                        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                      >
                        {{ itemB.project_profiles_count }}
                      </router-link>
                    </td>
                    <td class="text-center py-3 font-size-sm">
                      <router-link
                        :to="{ name: 'project_users', params: { segment: itemB.id, status: 'invited' } }"
                        tag="a"
                        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
                      >
                        {{ itemB.assigned_project_invites_count }}
                      </router-link>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>          
        </div>
        <b-alert v-if="error.active" show variant="light">
          {{ error.message }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectSegments",

  props: ["segmentationList", "segmentList", "pageLoader", "error", "segmentStats"],

  methods: {
    emitSegment(segment) {
      this.$emit('filterSegment',segment);
      window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
      })
    }
  },

  computed: {
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
