<template>
  <div>
    <div class="row mx-0">
      <ActivityTable
        :activityList="activityList"
        :activityStats="activityStats"
        :pageLoader="pageLoader"
        :error="error"
        :filters="activityFilters"
        @resetSegment="resetSegment"
      >
      </ActivityTable>
    </div>
    <div class="row mx-0">
      <ProjectHealthWidget
        :projectHealthData="projectHealth"
        :pageLoader="pageLoader"
        :error="error"
        :loginHistoryLoading="login_history_loading"
        @filter_changed="changeFilter"
        :projectStart="projectInfo.starts_on"
        :projectStage="projectInfo.system_project_stage_id"
      >
      </ProjectHealthWidget>
      <ProjectSegmentWidget
        :segmentationList="projectSegmentations"
        :segmentList="projectSegments"
        :pageLoader="pageLoader"
        :error="error"
        @filterSegment="filterSegment"
        :segmentStats="projectSegmentStats"
      >
      </ProjectSegmentWidget>
      <ProjectTextAnalysisWidget
        :textAnalysis="textAnalysis"
        :pageLoader="pageLoader"
        :error="error"
        :projectStage="projectInfo.system_project_stage_id"
      >
      </ProjectTextAnalysisWidget>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_ACTIVITY_LIST, POPULATE_ACTIVITY_STATS } from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_SEGMENT_LIST } from "@/core/services/store/project/project_segments.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { POPULATE_PROJECT_HEALTH, POPULATE_PROJECT_SEGMENT_STATS } from "@/core/services/store/project/project.module";
import {
  POPULATE_TEXT_ANALYSIS_SUMMARY,
  POPULATE_TEXT_ANALYSIS_EXCLUSION 
} from "@/core/services/store/project/project_text_analysis.module";

import ActivityTable from "@/view/content/activities/ActivityTable";
import ProjectSegmentWidget from "@/view/content/activities/ProjectSegments";
import ProjectHealthWidget from "@/view/content/activities/ProjectHealth";
import ProjectTextAnalysisWidget from "@/view/content/activities/ProjectTextAnalysis";

export default {
  name: "ViewProject",

  components: {
    ActivityTable,
    ProjectSegmentWidget,
    ProjectHealthWidget,
    ProjectTextAnalysisWidget
  },

  data() {
    return {
      login_history_loading: true,
      textAnalysis: [],
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 7
      },
      error: {
        active: false,
        message: ""
      },
      activityFilters: {
        segment: {
          id: null,
          name: `${this.$t("GENERAL_WIDGETS.PROJECT_SEGMENTS.NO_SEGMENT_FILTER")}`
        }
      },
      projectHealthFilter: {
        review_period: 1,
        start_date: null,
        end_date: null
      },
      offsetDateRange: {
        startDate: null,
        endDate: null
      },
      textAnalysisFilter: {
        word_limit: 50,
        excluded_words: []
      }
    };
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("NAVIGATION.PROJECT_MENU.DASHBOARD")}` }
    ]);
    this.fetchActivities();
    this.fetchActivityStats();
    this.fetchSegments();
    this.fetchSegmentations();
    this.fetchProjectHealth();
    this.fetchProjectTextAnalysis();
    this.fetchProjectSegmentStats();
  },

  methods: {
    fetchActivities() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          activityType: 'with',
          activityStage: 'with',
          projectSegments: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchActivityStats() {
      let payload = {
        projectId: this.projectInfo.id
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_STATS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity stats, please check back later or contact the helpdesk";
        });
    },
    fetchSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the segment list, please check back later or contact the helpdesk";
        });
    },
    fetchSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the segmentation list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectHealth() {
      this.login_history_loading = true;
      let payload = {
        projectId: this.projectInfo.id,
        params: this.projectHealthFilter
      };
      this.$store
        .dispatch(POPULATE_PROJECT_HEALTH, payload)
        .then(() => {
          if (this.projectInfo.system_project_stage_id > 2) {
            this.convertLoginHistoryTimezone();
          } else {
            this.pageLoader.componentsCompleted++;
          }
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project health, please check back later or contact the helpdesk";
        });
    },
    convertLoginHistoryTimezone() {
      let loginHistory = [];
      var startUTC = null;
      var currentUTC = null;
      var filterDate = null;
      var filterEnd = null;

      if (this.projectHealthFilter.review_period == 4) {
        filterDate = moment.parseZone(this.offsetDateRange.startDate).format("YYYY-MM-DD");
        filterEnd = moment.parseZone(this.offsetDateRange.endDate).format("YYYY-MM-DD");
      } else {
         if (this.projectHealthFilter.review_period == 1) {
          startUTC = moment.utc().subtract(6,'days').format("YYYY-MM-DD HH:mm:ss Z");
          currentUTC = moment.utc().format("YYYY-MM-DD HH:mm:ss Z");
        } else if (this.projectHealthFilter.review_period == 2) {
          startUTC = moment.utc().subtract(29,'days').format("YYYY-MM-DD HH:mm:ss Z");
          currentUTC = moment.utc().format("YYYY-MM-DD HH:mm:ss Z");
        } else if (this.projectHealthFilter.review_period == 3) {
          startUTC = this.projectInfo.starts_on;
          currentUTC = moment.utc().format("YYYY-MM-DD HH:mm:ss Z");
        }

        filterDate = this.convertToUserTimezone(startUTC);
        filterEnd = this.convertToUserTimezone(currentUTC);
      }

      while (filterDate <= filterEnd) {
        loginHistory[loginHistory.length] = { date: filterDate, value: 0 }
        filterDate = moment(filterDate, "YYYY-MM-DD").add(1,'days').format("YYYY-MM-DD");
      }

      let previousDate = null;
      let previousUser = null;

      this.projectHealth.login_history.forEach(login => {
        login.convertedDatetime = this.convertToUserTimezone(login.created_at);
        if (login.convertedDatetime != previousDate || login.user_id != previousUser) {
          loginHistory = this.updateLoginHistoryArray(loginHistory, login);
          previousDate = login.convertedDatetime;
          previousUser = login.user_id;
        }
      });

      this.projectHealth.login_history = loginHistory;

      if (filterDate >= filterEnd) {
        this.login_history_loading = false;
        this.pageLoader.componentsCompleted++;
      };
    },
    updateLoginHistoryArray(array, loginInfo) {
      array = array.map( function(login) {
        if( login.date == loginInfo.convertedDatetime ) {
          login.value++;
        }
        return login;
      });
      return array;
    },
    convertToUserTimezone(datetime) {
      if (this.currentUser.system_timezone_setting_id == 1) {
        return moment(datetime, "YYYY-MM-DD HH:mm:ss Z").format("YYYY-MM-DD");
      } else if (this.currentUser.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          return moment.parseZone(datetime, "YYYY-MM-DD HH:mm:ss Z").utc().utcOffset(this.projectInfo.systemTimezone.offset).format("YYYY-MM-DD");
        } else {
          return moment(datetime, "YYYY-MM-DD HH:mm:ss Z").format("YYYY-MM-DD");
        }
      } else {
        return moment.parseZone(datetime, "YYYY-MM-DD HH:mm:ss Z").utc().utcOffset(this.currentUser.systemTimezone.offset).format("YYYY-MM-DD");
      }
    },
    fetchProjectTextAnalysis() {
      this.$store
        .dispatch(POPULATE_TEXT_ANALYSIS_EXCLUSION, this.projectInfo.id)
        .then(() => {
          let payload = {
            projectId: this.projectInfo.id,
            params: {
              word_limit: 50,
              excluded_words: this.textAnalysisExclusion
            }
          };
          this.$store
            .dispatch(POPULATE_TEXT_ANALYSIS_SUMMARY, payload)
            .then((res) => {
              this.textAnalysis = res;
              this.pageLoader.componentsCompleted++;
            })
        })
    },
    fetchProjectSegmentStats() {
      let payload = {
        projectId: this.projectInfo.id
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_STATS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
    },
    filterSegment(value) {
      if (value == 'All') {
        if (this.activityFilters.segment.id == "All") {
          this.resetSegment();
        } else {
          this.activityFilters.segment.name = `${this.$t("GENERAL_WIDGETS.PROJECT_SEGMENTS.ALL_PARTICIPANTS")}`;
          this.activityFilters.segment.id = 'All';
        }
      } else {
        if (this.activityFilters.segment.id == value.id) {
          this.resetSegment();
        } else {
          this.activityFilters.segment.name = value.project_segmentation.name + ": " + value.name;
          this.activityFilters.segment.id = value.id;         
        }        
      }
    },
    resetSegment() {
      this.activityFilters.segment.name = `${this.$t("GENERAL_WIDGETS.PROJECT_SEGMENTS.NO_SEGMENT_FILTER")}`;
      this.activityFilters.segment.id = null; 
    },
    changeFilter(value) {
      if (value.type != 4) {
        this.projectHealthFilter.review_period = value.type;
        this.fetchProjectHealth();
      } else {
        this.offsetDateRange.startDate = value.offsetStartDate;
        this.offsetDateRange.endDate = value.offsetEndDate;
        this.projectHealthFilter.review_period = value.type;
        this.projectHealthFilter.start_date = value.lowerRange;
        this.projectHealthFilter.end_date = value.upperRange;
        this.fetchProjectHealth();
      }

    } 
  },

  computed: {
    ...mapGetters([
      "activityList",
      "activityStats",
      "projectSegments",
      "projectSegmentations",
      "clientInfo",
      "projectInfo",
      "projectHealth",
      "textAnalysisExclusion",
      "projectSegmentStats",
      "currentUser"
    ])
  }
}
</script>
