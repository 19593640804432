import { render, staticRenderFns } from "./ProjectHealth.vue?vue&type=template&id=2e740509&scoped=true&"
import script from "./ProjectHealth.vue?vue&type=script&lang=js&"
export * from "./ProjectHealth.vue?vue&type=script&lang=js&"
import style0 from "./ProjectHealth.vue?vue&type=style&index=0&id=2e740509&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e740509",
  null
  
)

export default component.exports