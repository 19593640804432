<template>
  <div>
    <div
      class="progress"
      v-b-tooltip.hover
      :title="progressTooltip"
      v-if="activity.system_activity_stage_id > 2"
    >
      <div
        class="progress-bar"
        role="progressbar"
        :style="'width:' + progress + '%'"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :class="progressClass"
      >
      </div>
    </div>
    <div v-else>
      <p class="text-center mb-0">-</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParticipantResponseRate",

  props: {
    responseRate: {
      type: Object,
      required: true,
      validator: function (obj) {
        return 'participant_response_rate' in obj &&
          'unique_response_count' in obj &&
          'total_participants' in obj
      }
    },
    activity: { type: Object, required: true }
  },

  computed: {
    progress: function() {
      return this.responseRate.participant_response_rate;
    },
    progressClass: function() {
      if(this.progress >= 80) {
        return "bg-success";
      } else if (this.progress < 20) {
        return "bg-danger";
      } else {
        return "bg-warning";
      }
    },
    progressTooltip: function() {
      return this.progress +
        "% " +  `${this.$t("GENERAL.PROGRESS.RESPONSE_RATE")}` +
        " (" + this.responseRate.unique_response_count + "/" +
        this.responseRate.total_participants + ")";
    }
  }
};
</script>
