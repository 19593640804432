<template>
  <div class="col-xl-4 px-0">
    <div
      class="card card-custom gutter-b h-100"
      :class="error.active ? 'bordered-card-danger mr-3' : 'bordered-card-primary mr-3'"
    >
      <div class="card-header px-5">
        <div class="card-title d-flex justify-content-between w-100">
          <h3 class="card-label">
            {{ $t('GENERAL_WIDGETS.PROJECT_HEALTH.TITLE') }}
          </h3>
          <div class="card-toolbar">
            <amChartsExport
              v-if="chart != null && currentUser.relation_to_project != 'observer'"
              position="left"
              :dataTypes="['png','jpg','svg','json','csv']"
              :chart="chart"
              title="project_health"
            >
            </amChartsExport>
          </div>
        </div>
      </div>
      <div class="card-body px-5 py-5">
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>          
        </div>
        <div class="d-flex justify-content-center" v-if="loadingComplete && !error.active && projectStage >= 3">
          <b-form-group
            id="user-login-range-filter"
            :label="$t('FORMS.INPUTS.USER_LOGIN_RANGE') + '*'"
            label-for="user-login-range-filter"
            class="col-xl-6 px-5"
            :label-sr-only="true"
          >
            <b-form-select
              id="user-login-range-filter"
              v-model="login_range_filter"
              :options="login_range_filter_options"
              class="form-control form-control-solid h-auto py-4 px-6"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
          <InputGenericDateRangePicker
            id="date-filter"
            class="col-xl-6 px-5"
            :class="{ 'd-none': login_range_filter != 4 && login_range_filter != null }"
            :model="$v.dateRange"
            :showInline="false"
            :minDate="projectStart"
            ref="picker"
            :submissionStates="submissionStates"
            :placeholder="$t('REVIEW.STREAM.FILTER_DATE')"
            @update="dateRangeUpdated($event)"
          >
          </InputGenericDateRangePicker>
        </div>
        <div class="row mb-10" :class="{ 'd-none' : projectStage < 3 }">
          <div class="widget_chart" ref="chartdiv"></div>
        </div>
        <div v-if="loadingComplete && !error.active && projectStage >= 3">
          <div class="d-flex justify-content-between pr-3 mb-3">
            <h6>{{ $t('TABLES.SEGMENT_HEADERS.PARTICIPANTS')}} : {{ projectHealthData.completion_rate.totalParticipants }} </h6>
            <a v-if="currentUser.relation_to_project == 'super_admin'" href="javascript:void(0)" class="kt-link" @click="generateParticipationReport">
              <span>{{ $t("GENERAL_WIDGETS.PROJECT_HEALTH.DOWNLOAD") }}</span>
            </a>
          </div>
          <div class="progress" v-b-tooltip.hover :title="calculateProgressTooltip(projectHealthData.completion_rate)">
            <div
              class="progress-bar"
              role="progressbar"
              :style="'width:' + projectHealthData.completion_rate.profileCompletionRate + '%'"
              :aria-valuenow="projectHealthData.completion_rate.profileCompletionRate"
              aria-valuemin="0"
              aria-valuemax="100"
              :class="calculateProgressClass(projectHealthData.completion_rate)"
            >
            </div>
          </div>
        </div>
        <b-alert v-if="projectStage < 3 && !error.active && loadingComplete" show variant="light">
          {{ $t('GENERAL_WIDGETS.PROJECT_HEALTH.NOT_READY') }}
        </b-alert>
        <b-alert v-if="error.active" show variant="light">
          {{ error.message }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import InputGenericDateRangePicker from "@/modules/together-helpers/components/generic-inputs/GenericDateRangePicker"
import amChartsExport from "@/view/components/AMChartExport";
import { validationMixin } from "vuelidate";

import { CREATE_PROJECT_REPORT } from "@/core/services/store/project/project_reports.module";

am4core.useTheme(am4themes_animated);

export default {
  mixins: [validationMixin],

  name: "ProjectHealth",

  props: ["projectHealthData", "pageLoader", "error", "projectStart", "projectStage", "loginHistoryLoading"],

  components: { InputGenericDateRangePicker, amChartsExport },

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      login_range_filter_options: [
        { id: 1, name: `${this.$t("GENERAL_WIDGETS.PROJECT_HEALTH.FILTER_OPTION_1")}` },
        { id: 2, name: `${this.$t("GENERAL_WIDGETS.PROJECT_HEALTH.FILTER_OPTION_2")}` },
        { id: 3, name: `${this.$t("GENERAL_WIDGETS.PROJECT_HEALTH.FILTER_OPTION_3")}` },
        { id: null, name: `${this.$t("GENERAL_WIDGETS.PROJECT_HEALTH.FILTER_OPTION_4")}` }
      ],
      login_range_filter: 1,
      offsetDateRange: {
        startDate: null,
        endDate: null
      },
      dateRange: {
        startDate: null,
        endDate: null
      },
      chart: null
    }
  },

  validations: {
    dateRange: {}
  },

  methods: {
    generateChart() {
      if (this.projectStage >= 3) {
        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

        chart.paddingRight = 20;

        let data = this.projectHealthData.login_history;

        chart.data = data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.strokeWidth = 3;
        series.stroke = am4core.color("#4d648d");
        series.fill = am4core.color("#4d648d");

        var bullet = series.bullets.push(new am4charts.Bullet());
        var square = bullet.createChild(am4core.Circle);
        square.width = 7;
        square.height = 7;
        square.horizontalCenter = "middle";
        square.verticalCenter = "middle";

        square.stroke = am4core.color("#4d648d");
        square.strokeWidth = 1;

        var shadow = new am4core.DropShadowFilter();
        shadow.dx = 2;
        shadow.dy = 2;
        square.filters.push(shadow);

        chart.maskBullets = false;

        series.tooltipText = "{valueY.value}";
        chart.cursor = new am4charts.XYCursor();

        let title = chart.titles.create();
        title.text = `${this.$t("GENERAL_WIDGETS.PROJECT_HEALTH.CHART_TITLE")}`;
        title.fontSize = 12;
        title.marginBottom = 5;

        chart.exporting.adapter.add("data", function(data) {
          data.data = series.data
          return data;
        });

        this.chart = chart;
      }
    },
    calculateProgressTooltip(completionRate) {
      return completionRate.profileCompletionRate + "% " +  `${this.$t("GENERAL.PROGRESS.PROFILE_COMPLETION")}` + " (" + completionRate.completeProfiles + "/" + completionRate.totalParticipants + ")";
    },
    calculateProgressClass(progress) {
      if(progress.profileCompletionRate >= 80) {
        return "bg-success";
      } else if (progress.profileCompletionRate < 20) {
        return "bg-danger";
      } else {
        return "bg-warning";
      }
    },
    dateRangeUpdated(event) {
      this.dateRange = event.utcDates;
      this.offsetDateRange = event.offsetDates;
      this.login_range_filter = 4;
    },
    generateParticipationReport: function() {
      let payload = {
        projectId: this.projectInfo.id,
        data: {
          title: "Participation Report",
          type: 2,
          dateRange: this.dateRange
        }
      };

      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.HEALTH_REPORT_CHALLENGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.HEALTH_REPORT_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.HEALTH_REPORT_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(CREATE_PROJECT_REPORT, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.HEALTH_REPORT_SUCCESS.TITLE")}`,
              text: `${this.$t("SWEETALERTS.HEALTH_REPORT_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.HEALTH_REPORT_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    }
  },
  
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  watch: {
    loginHistoryLoading: function(newValue, oldValue) {
      if(oldValue == true && newValue == false)
      {
        this.generateChart();
      }
    },
    login_range_filter: function(newValue, oldValue) {
      if(oldValue != newValue && this.login_range_filter != null && this.login_range_filter != 4) {
        this.$emit('filter_changed', { type: newValue })
      } else if (oldValue != newValue && this.login_range_filter == 4) {
        if (this.lowerRange != null && this.upperRange != null) {
          this.$emit('filter_changed', { type: newValue, lowerRange: this.lowerRange, upperRange: this.upperRange, offsetStartDate: this.offsetDateRange.startDate, offsetEndDate: this.offsetDateRange.endDate });
        }
        this.login_range_filter = null;
      }
    },
    projectHealthData: function(newValue, oldValue) {
      if(oldValue.login_history != newValue.login_history && this.loadingComplete)
      {
        if (this.chart) {
          this.chart.dispose();
        }
        this.generateChart();
      }
    }
  },

  computed: {
    ...mapGetters([
        "projectInfo",
        "currentUser"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    lowerRange: function() {
      if (this.dateRange.startDate != null) {
        return this.dateRange.startDate;
      } else {
        return null;
      };
    },
    upperRange: function() {
      if (this.dateRange.endDate != null) {
        return this.dateRange.endDate;
      } else {
        return null;
      };
    }
  }
};
</script>

<style scoped>
  .widget_chart {
    width: 100%;
    height: 300px;
  }
</style>
